window.addEventListener('scroll', function() {
  var header = document.querySelector('.o-header');
  if (window.scrollY > header.offsetTop) {
    header.classList.add('sticky');
  } else {
    header.classList.remove('sticky');
  }
});
document.addEventListener("DOMContentLoaded", function() {
  function checkWindowSize() {
      // Check if window width is less than 992 pixels
      if (window.innerWidth < 992) {
          // Remove the class from the element(s)
          document.querySelectorAll('.o-howitwork__mainwrap').forEach(function(element) {
              element.classList.remove('js-vertical-wrap-main');
          });
          document.querySelectorAll('.o-howitwork__textlist').forEach(function(element) {
            element.classList.remove('js-vertical-text');
          });
          document.querySelectorAll('.o-howitwork__imagelist').forEach(function(element) {
            element.classList.remove('js-vertical-image');
          });
          document.querySelectorAll('.o-howitwork__tabcontent').forEach(function(element) {
            element.classList.remove('js-vertical-paragraph');
          });
      }
  }

  // Run the function once on page load
  checkWindowSize();

  // Run the function whenever the window is resized
  window.addEventListener('resize', checkWindowSize);
});
